import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Typography, Box, Stack, IconButton, useTheme, Tooltip, Grid } from '@mui/material';
import SocialMediaIcons from '../ListPage/SocialMediaIcons';
import ContactIcons from '../ListPage/ContactIcons';
import { Deck, TableRestaurant, Restaurant, Tapas, AirportShuttle, DeliveryDining, Pets, FamilyRestroom, FollowTheSigns} from '@mui/icons-material';
import { Helmet } from 'react-helmet-async'; // Import Helmet

const BreweryDetail = ({ craftbevia }) => {
    const theme = useTheme();
    const { ID } = useParams();
    const data = craftbevia.find((data) => data.ID === parseInt(ID));
    
    if (!data) {
      return <div>Brewery not found</div>;
    }
  
    return (
        <Box padding={2}>
          {/* Set meta tags for SEO */}
          <Helmet>
            <title>{`${data.Name} - Craftbevia`}</title>
            <meta name="description" content={`Explore details of ${data.Name} brewery located in ${data.City}, ${data.State}.`} />
          </Helmet>
          <Card
            sx={{
              width: '100%',
              flexGrow: 1,
              marginBottom: 1,
              border: `1px solid ${theme.palette.primary.main}`,
            }}
          >
            <CardContent>
              <Box textAlign="center" mb={2}>
                <Typography variant="h2" fontWeight="bold">
                  {data.Name}
                </Typography>
              </Box>
  
              <Grid container spacing={2}>
                {/* Location */}
                <Grid item xs={12} align="center">
                  <Typography variant="h4">Location</Typography>
                  <Typography variant="body1">
                    {data.Street}
                    <br />
                    {data.City}, {data.State} {data.zipCode}
                  </Typography>
                </Grid>
  
                {/* Social Media and Contact */}
                <Grid item xs={6} align="center">
                  <Typography variant="h4">Socials</Typography>
                  <SocialMediaIcons
                    instagram={data.Instagram}
                    facebook={data.Facebook}
                    twitter={data.Twitter}
                    iconSize={32}
                  />
                </Grid>
                <Grid item xs={6} align="center">
                  <Typography variant="h4" >Contact</Typography>
                  <ContactIcons
                    email={data.Email}
                    phoneNumber={data.phoneNumber}
                    website={data.Website}
                    iconSize={32}
                  />
                </Grid>
  
                {/* Information */}
                <Grid item xs={12}>
                  <Typography variant="h4" align="center">General Information</Typography>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    {/* Restaurant Icon */}
                    {data.kitchenAvailability === 'YES' ? (
                      <Tooltip title="Kitchen">
                        <IconButton color="primary">
                          <Restaurant />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Kitchen">
                        <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                          <Restaurant />
                        </IconButton>
                      </Tooltip>
                    )}
  
                    {/* Tapas Icon */}
                    {data.snacksAvailability === 'YES' ? (
                      <Tooltip title="Snacks">
                        <IconButton color="primary">
                          <Tapas />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Snacks">
                        <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                          <Tapas />
                        </IconButton>
                      </Tooltip>
                    )}
  
                    {/* AirportShuttle Icon */}
                    {data.foodTruckAvailability === 'YES' ? (
                      <Tooltip title="Food Trucks/Vendor">
                        <IconButton color="primary">
                          <AirportShuttle />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Food Trucks/Vendor">
                        <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                          <AirportShuttle />
                        </IconButton>
                      </Tooltip>
                    )}
  
                    {/* DeliveryDining Icon */}
                    {data.bringYourOwnFoodFriendly === 'YES' ? (
                      <Tooltip title="Bring Your Own Food Friendly">
                        <IconButton color="primary">
                          <DeliveryDining />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Bring Your Own Food Friendly">
                        <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                          <DeliveryDining />
                        </IconButton>
                      </Tooltip>
                    )}
  
                    {/* Deck Icon */}
                    {data.outdoorSeating === 'YES' ? (
                      <Tooltip title="Outdoor Seating">
                        <IconButton color="primary">
                          <Deck />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Outdoor Seating">
                        <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                          <Deck />
                        </IconButton>
                      </Tooltip>
                    )}
  
                    {/* Table Restaurant Icon */}
                    {data.indoorSeating === 'YES' ? (
                      <Tooltip title="Indoor Seating">
                        <IconButton color="primary">
                          <TableRestaurant />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Indoor Seating">
                        <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                          <TableRestaurant />
                        </IconButton>
                      </Tooltip>
                    )}
  
                    {/* Pets Icon */}
                    {(data.petFriendly === 'YES' || data.petFriendly === 'YES (outdoors)' || data.petFriendly === 'YES (indoors)') ? (
                      <Tooltip title="Pet Friendly">
                        <IconButton color="primary">
                          <Pets />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Pet Friendly">
                        <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                          <Pets />
                        </IconButton>
                      </Tooltip>
                    )}
  
                    {/* Family Restroom Icon */}
                    {data.familyFriendly === 'YES' ? (
                      <Tooltip title="Family Friendly">
                        <IconButton color="primary">
                          <FamilyRestroom />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Family Friendly">
                        <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                          <FamilyRestroom />
                        </IconButton>
                      </Tooltip>
                    )}
  
                    {/* FollowTheSigns Icon */}
                    {data.tourAvailability === 'YES' ? (
                      <Tooltip title="Tour Available">
                        <IconButton color="primary">
                          <FollowTheSigns />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Tour Available">
                        <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                          <FollowTheSigns />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
    );
};

export default BreweryDetail;