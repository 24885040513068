import React, { useState } from 'react';
import { Typography, Box, Stack, List, Button, Card, CardContent, Grid } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';


const ITEMS_PER_PAGE = 30; // Number of breweries to show per page

// Function to calculate the distance between two latitude-longitude coordinates using the Haversine formula
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
    Math.cos((lat2 * Math.PI) / 180) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance; // Distance in km
};

const InteractiveMapList = ({ breweries, userLocation }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const theme = useTheme();

  // Calculate the index of the first and last brewery to show on the current page
  const indexOfLastBrewery = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstBrewery = indexOfLastBrewery - ITEMS_PER_PAGE;

  // Slice the data based on the current page and sort by distance
  const currentBreweries = breweries.map((brewery) => {
    const distance = calculateDistance(
      userLocation.lat,
      userLocation.lng,
      brewery.Latitude,
      brewery.Longitude
    );
    return { ...brewery, distance };
  }).sort((a, b) => a.distance - b.distance).slice(indexOfFirstBrewery, indexOfLastBrewery);

  // Function to handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Function to go to the previous page
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Function to go to the next page
  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(breweries.length / ITEMS_PER_PAGE))
    );
  };

  // Function to calculate the total number of pages
  const totalPages = Math.ceil(breweries.length / ITEMS_PER_PAGE);

  // Function to open Google Maps with the selected brewery's location
  const openGoogleMaps = (latitude, longitude) => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <div>
      <Box display="flex" justifyContent="center" my={2} gap={2}>
        <Typography variant="h4">Breweries Sorted by Distance Away</Typography>
      </Box>
      <List>
        <Grid container spacing={2}>
          {currentBreweries.map((brewery, index) => (
            <Grid item xs={12} sm={6} md={4} key={brewery.ID}>
              <Card sx={{ width: '100%', flexGrow: 1, marginBottom: 1, border: `1px solid ${theme.palette.primary.main}` }}>
                <CardContent>
                  <Box>
                    <div style={{ textAlign: 'center'}}>
                     {/* Create a Link around the name */}
          <Link to={`/breweries/${brewery.ID}`} style={{ textDecoration: 'none' }}>
              <Typography variant="h5" fontWeight="bold">
                {brewery.Name}
              </Typography>
            </Link>
                    </div>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="flex-start" mt={2}>
                    <Stack>
                      <Typography variant="h6" color="textSecondary" gutterBottom>
                        {brewery.Street}
                        <br />
                        {brewery.City}, {brewery.State} {brewery.zipCode}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="h6" color="textSecondary" gutterBottom>
                        {'Distance Away'}
                        <br />
                        {`${brewery.distance.toFixed(2)} km`}
                      </Typography>
                    </Stack>
                  </Box>

                  {/* Google Maps Button */}
                  <Box mt={2} display="flex" justifyContent="center">
                    <Button variant="contained" onClick={() => openGoogleMaps(brewery.Latitude, brewery.Longitude)}>
                      View on Google Maps
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </List>
      {/* Pagination */}
      <Box display="flex" justifyContent="center" alignItems="center" my={2}>
        <ArrowLeftIcon
          onClick={handlePreviousPage}
          style={{ cursor: 'pointer', fontSize: '1.5rem', marginRight: '0.5rem' }}
        />
        {Array.from({ length: totalPages }).map((_, index) => (
          <Typography
            key={index}
            variant="body1"
            style={{
              cursor: 'pointer',
              fontWeight: index + 1 === currentPage ? 'bold' : 'normal',
              color: index + 1 === currentPage ? theme.palette.primary.main : 'inherit',
              marginRight: '0.5rem',
            }}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Typography>
        ))}
        <ArrowRightIcon
          onClick={handleNextPage}
          style={{ cursor: 'pointer', fontSize: '1.5rem', marginLeft: '0.5rem' }}
        />
      </Box>
    </div>
  );
};

export default InteractiveMapList;
