import React from 'react';
import { Tooltip } from '@mui/material';
import { Phone, Email, Link } from '@mui/icons-material';

const ContactIcons = ({ email, phoneNumber, website, iconSize = 32 }) => {
  const iconStyle = { fontSize: iconSize };

  // Function to check if a value is not null or undefined
  const isNotNull = (value) => value !== 'NULL' && value !== undefined;

  // Function to handle email click
  const handleEmailClick = () => {
    if (isNotNull(email)) {
      window.location.href = `mailto:${email}`;
    }
  };

  // Function to handle phone click
  const handlePhoneClick = () => {
    if (isNotNull(phoneNumber)) {
      window.location.href = `tel:${phoneNumber}`;
    }
  };

  return (
    <div>
      {/* Email Icon */}
      {isNotNull(email) ? (
        <Tooltip title="Email">
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            <Email style={iconStyle} onClick={handleEmailClick} />
          </a>
        </Tooltip>
      ) : (
        <Tooltip title="Email">
          <Email style={{ ...iconStyle, color: 'grey' }} />
        </Tooltip>
      )}

      {/* Phone Icon */}
      {isNotNull(phoneNumber) ? (
        <Tooltip title="Phone">
          <a href={`tel:${phoneNumber}`} target="_blank" rel="noopener noreferrer">
            <Phone style={iconStyle} onClick={handlePhoneClick} />
          </a>
        </Tooltip>
      ) : (
        <Tooltip title="Phone">
          <Phone style={{ ...iconStyle, color: 'grey' }} />
        </Tooltip>
      )}

      {/* Website Icon */}
      {isNotNull(website) ? (
        <Tooltip title="Website">
          <a href={website} target="_blank" rel="noopener noreferrer">
            <Link style={iconStyle} />
          </a>
        </Tooltip>
      ) : (
        <Tooltip title="Website">
          <Link style={{ ...iconStyle, color: 'grey' }} />
        </Tooltip>
      )}
    </div>
  );
};

export default ContactIcons;