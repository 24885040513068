import React from "react";
import { Container, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    // Container to limit the width of the content and add spacing from top
    <Container maxWidth="md" sx={{ mt: 3 }}>
      {/* Privacy Policy title */}
      <Typography variant="h2" gutterBottom>
        Privacy Policy
      </Typography>

      {/* Effective date */}
      <Typography variant="body1" paragraph>
        Effective Date: July 2023
      </Typography>

      {/* Introduction */}
      <Typography variant="body1" paragraph>
        Welcome to Craftbevia. This Privacy Policy outlines how we collect, use, and protect the information you provide while using our comprehensive brewery database service.
      </Typography>

      {/* Age Verification */}
      <Typography variant="body1" paragraph>
        1. Age Verification
        <br />
        We take the privacy and safety of our users seriously. As this website is intended for users who are 21 years of age or older, we do not knowingly collect personal information from individuals under 21. By using our website, you confirm that you are of legal drinking age in your jurisdiction.
      </Typography>

      {/* Information We Collect */}
      <Typography variant="body1" paragraph>
        2. Information We Collect
        <br />
        We collect the following types of information from users:
        <br />
        a. Personal Information: When you use our website, we may collect personal information you voluntarily provide, such as your name, email address, phone number, and location.
        <br />
        b. Brewery Information: We gather data related to breweries, such as social media and website links, contact information (phone numbers and email addresses), addresses, and details on pet-friendliness, family-friendliness, and food availability.
        <br />
        c. Usage Data: We may collect non-personal information about your interactions with our website, such as IP addresses, browser types, pages viewed, and other statistical data.
      </Typography>

      {/* Use of Information */}
      <Typography variant="body1" paragraph>
        3. Use of Information
        <br />
        We use the collected information to provide and improve our comprehensive brewery database service. Specifically, we may use your information for the following purposes:
        <br />
        a. To display brewery information and relevant details.
        <br />
        b. To respond to user inquiries and provide customer support.
        <br />
        c. To send occasional updates and newsletters related to breweries or our services.
        <br />
        d. To enhance user experience and optimize our website.
      </Typography>

      {/* Data Accuracy and Disclaimer */}
      <Typography variant="body1" paragraph>
        4. Data Accuracy and Disclaimer
        <br />
        While we strive to provide accurate and up-to-date information, please be aware that brewery policies and information may change over time. We cannot guarantee the accuracy of all data displayed on our website. Users are advised to verify the information directly with the respective breweries before making decisions.
      </Typography>

      {/* Third-Party Links */}
      <Typography variant="body1" paragraph>
        5. Third-Party Links
        <br />
        Our website may contain links to third-party websites for your convenience. Please note that clicking on these links will take you outside our domain. We do not endorse or have control over the content or policies of third-party websites. Users are encouraged to review the privacy policies and terms of use of those external sites.
      </Typography>

      {/* Data Security */}
      <Typography variant="body1" paragraph>
        6. Data Security
        <br />
        We take reasonable measures to safeguard your information and maintain data security. However, please understand that no method of transmission over the internet or electronic storage is completely secure. We cannot guarantee the absolute security of your data.
      </Typography>

      {/* Children's Privacy */}
      <Typography variant="body1" paragraph>
        7. Children's Privacy
        <br />
        Our services are not directed to children under the age of 21. We do not knowingly collect personal information from individuals under 21. If you believe we have inadvertently collected data from someone under 21, please contact us to have it removed.
      </Typography>

      {/* Changes to the Privacy Policy */}
      <Typography variant="body1" paragraph>
        8. Changes to the Privacy Policy
        <br />
        We reserve the right to modify this Privacy Policy at any time. Any updates will be effective upon posting the revised version on our website. Your continued use of our services after such changes constitutes acceptance of the updated Privacy Policy.
      </Typography>

      {/* Contact Information */}
      <Typography variant="body1" paragraph>
        9. Contact Us
        <br />
        If you have any questions, concerns, or feedback regarding our Privacy Policy or data practices, please contact us at craftbevia@gmail.com.
      </Typography>
    </Container>
  );
};

// Export the PrivacyPolicy component
export default PrivacyPolicy;