// Import necessary modules and components
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './MainPage/MainPage';
import NavBar from './General/NavBar';
import Footer from './General/Footer';
import ListView from './ListPage/ListView';
import MapView from './MapPage/MapView';
import TableView from './TablePage/TableView';
import BreweryDetail from './Brewery/BreweryDetail';
import PrivacyPolicy from './General/PrivacyPolicy';
import TermsOfUse from './General/TermsOfUse';
import Disclaimer from './General/Disclaimer';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Import the JSON data containing brewery information for New England states
import craftbeviaData from './data/brewery_NewEngland.json';

// Import required fonts from fontsource
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// App component function
const App = () => {
  // Separate the data for different states
  const statesData = {
    newengland: craftbeviaData, // For the '/list/newengland' route
    CT: craftbeviaData.filter((data) => data.State === 'CT'),
    ME: craftbeviaData.filter((data) => data.State === 'ME'), 
    VT: craftbeviaData.filter((data) => data.State === 'VT'),
    NH: craftbeviaData.filter((data) => data.State === 'NH'), 
    MA: craftbeviaData.filter((data) => data.State === 'MA'), 
    RI: craftbeviaData.filter((data) => data.State === 'RI'), 
  };

  // Define the list of states to be passed as props
  const newEngland = ['ME', 'CT', 'RI', 'MA', 'NH', 'VT'];
  const VTStates = ['VT'];
  const NHStates = ['NH'];
  const MAStates = ['MA'];
  const CTStates = ['CT'];
  const RIStates = ['RI'];
  const MEStates = ['ME'];
  
  return (
    <HelmetProvider>
    <Router>
      <div>
        {/* Render the navigation bar */}
        <ThemeProvider theme={theme}>
        <NavBar />
        <Routes>
          {/* Home Page */}
          <Route
              path="/"
              element={
                <>
                  <Helmet>
                    <title>Home Page - Craftbevia</title>
                    <meta
                      name="description"
                      content="The home page for Craftbevia."
                    />
                  </Helmet>
                  <MainPage />
                </>
              }
            />
          {/* Disclaimer Page */}
          <Route
              path="/disclaimer"
              element={
                <>
                  <Helmet>
                    <title>Disclaimer - Craftbevia</title>
                    <meta
                      name="description"
                      content="The disclaimer page for Craftbevia."
                    />
                  </Helmet>
                  <Disclaimer />
                </>
              }
            />
            {/* Terms of Use Page */}
            <Route
              path="/terms"
              element={
                <>
                  <Helmet>
                    <title>Terms of Use - Craftbevia</title>
                    <meta
                      name="description"
                      content="The terms of use page for Craftbevia."
                    />
                  </Helmet>
                  <TermsOfUse />
                </>
              }
            />
            {/* Privacy Policy Page */}
            <Route
              path="/privacy"
              element={
                <>
                  <Helmet>
                    <title>Privacy Policy - Craftbevia</title>
                    <meta
                      name="description"
                      content="The privacy policy page for Craftbevia."
                    />
                  </Helmet>
                  <PrivacyPolicy />
                </>
              }
            />

          {/* Render the ListView component with different data and state props for each state */}
          <Route
  path="/breweries/list/newengland"
  element={
    <>
      <Helmet>
        <title>List of New England Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Explore a list of breweries in New England. Discover local craft beer and brewing culture."
        />
      </Helmet>
      <ListView
        data={statesData.newengland}
        states={newEngland}
        stateFullName="New England"
      />
    </>
  }
/>

<Route
  path="/breweries/list/VT"
  element={
    <>
      <Helmet>
        <title>List of Vermont Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Explore a list of breweries in Vermont. Find the best craft beer destinations in the Green Mountain State."
        />
      </Helmet>
      <ListView data={statesData.VT} states={VTStates} stateFullName="Vermont" />
    </>
  }
/>

<Route
  path="/breweries/list/NH"
  element={
    <>
      <Helmet>
        <title>List of New Hampshire Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Explore a list of breweries in New Hampshire. Explore the rich brewing scene in the Granite State."
        />
      </Helmet>
      <ListView
        data={statesData.NH}
        states={NHStates}
        stateFullName="New Hampshire"
      />
    </>
  }
/>

<Route
  path="/breweries/list/MA"
  element={
    <>
      <Helmet>
        <title>List of Massachusetts Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Explore a list of breweries in Massachusetts. Experience the craft beer culture in the Bay State."
        />
      </Helmet>
      <ListView
        data={statesData.MA}
        states={MAStates}
        stateFullName="Massachusetts"
      />
    </>
  }
/>

<Route
  path="/breweries/list/CT"
  element={
    <>
      <Helmet>
        <title>List of Connecticut Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Explore a list of breweries inn Connecticut. Sample local brews in the Constitution State."
        />
      </Helmet>
      <ListView
        data={statesData.CT}
        states={CTStates}
        stateFullName="Connecticut"
      />
    </>
  }
/>

<Route
  path="/breweries/list/RI"
  element={
    <>
      <Helmet>
        <title>List of Rhode Island Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Explore a list of breweries in Rhode Island. Enjoy craft beer on the shores of the Ocean State."
        />
      </Helmet>
      <ListView
        data={statesData.RI}
        states={RIStates}
        stateFullName="Rhode Island"
      />
    </>
  }
/>

<Route
  path="/breweries/list/ME"
  element={
    <>
      <Helmet>
        <title>List of Maine Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Explore a list of breweries in Maine. Experience the vibrant craft beer scene in the Pine Tree State."
        />
      </Helmet>
      <ListView data={statesData.ME} states={MEStates} stateFullName="Maine" />
    </>
  }
/>

          {/* Render the MapView component with different data and state props for each state */}
          <Route
  path="/breweries/map/newengland"
  element={
    <>
      <Helmet>
        <title>Map of New England Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Explore an interactive map of breweries in New England. Find your favorite craft beer spots."
        />
      </Helmet>
      <MapView
        data={statesData.newengland}
        states={newEngland}
        stateFullName="New England"
        selectedState="NewEngland"
      />
    </>
  }
/>

<Route
  path="/breweries/map/VT"
  element={
    <>
      <Helmet>
        <title>Map of Vermont Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Navigate an interactive map of breweries in Vermont. Discover the best craft beer locations in the Green Mountain State."
        />
      </Helmet>
      <MapView
        data={statesData.VT}
        states={VTStates}
        stateFullName="Vermont"
        selectedState="VT"
      />
    </>
  }
/>

<Route
  path="/breweries/map/NH"
  element={
    <>
      <Helmet>
        <title>Map of New Hampshire Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Explore an interactive map of breweries in New Hampshire. Plan your craft beer adventure in the Granite State."
        />
      </Helmet>
      <MapView
        data={statesData.NH}
        states={NHStates}
        stateFullName="New Hampshire"
        selectedState="NH"
      />
    </>
  }
/>

<Route
  path="/breweries/map/MA"
  element={
    <>
      <Helmet>
        <title>Map of Massachusetts Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Navigate an interactive map of breweries in Massachusetts. Immerse yourself in the craft beer culture of the Bay State."
        />
      </Helmet>
      <MapView
        data={statesData.MA}
        states={MAStates}
        stateFullName="Massachusetts"
        selectedState="MA"
      />
    </>
  }
/>

<Route
  path="/breweries/map/CT"
  element={
    <>
      <Helmet>
        <title>Map of Connecticut Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Explore an interactive map of breweries in Connecticut. Discover unique brews in the Constitution State."
        />
      </Helmet>
      <MapView
        data={statesData.CT}
        states={CTStates}
        stateFullName="Connecticut"
        selectedState="CT"
      />
    </>
  }
/>

<Route
  path="/breweries/map/RI"
  element={
    <>
      <Helmet>
        <title>Map of Rhode Island Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Navigate an interactive map of breweries in Rhode Island. Enjoy craft beer by the ocean in the Ocean State."
        />
      </Helmet>
      <MapView
        data={statesData.RI}
        states={RIStates}
        stateFullName="Rhode Island"
        selectedState="RI"
      />
    </>
  }
/>

<Route
  path="/breweries/map/ME"
  element={
    <>
      <Helmet>
        <title>Map of Maine Breweries - Craftbevia</title>
        <meta
          name="description"
          content="Explore an interactive map of breweries in Maine. Experience the vibrant craft beer scene in the Pine Tree State."
        />
      </Helmet>
      <MapView
        data={statesData.ME}
        states={MEStates}
        stateFullName="Maine"
        selectedState="ME"
      />
    </>
  }
/>

          {/* Render the TableView component with different data and state props for each state */}
          <Route
  path="/breweries/table/newengland"
  element={
    <>
      <Helmet>
        <title>Table of New England Breweries - Craftbevia</title>
        <meta
          name="description"
          content="View a table of breweries in New England. Explore details and information about craft beer in the region."
        />
      </Helmet>
      <TableView
        data={statesData.newengland}
        states={newEngland}
        stateFullName="New England"
        selectedState="NewEngland"
      />
    </>
  }
/>

<Route
  path="/breweries/table/VT"
  element={
    <>
      <Helmet>
        <title>Table of Vermont Breweries - Craftbevia</title>
        <meta
          name="description"
          content="View a table of breweries in Vermont. Access detailed information about the best craft beer spots in the Green Mountain State."
        />
      </Helmet>
      <TableView
        data={statesData.VT}
        states={VTStates}
        stateFullName="Vermont"
        selectedState="VT"
      />
    </>
  }
/>

<Route
  path="/breweries/table/NH"
  element={
    <>
      <Helmet>
        <title>Table of New Hampshire Breweries - Craftbevia</title>
        <meta
          name="description"
          content="View a table of breweries in New Hampshire. Explore essential details about craft beer in the Granite State."
        />
      </Helmet>
      <TableView
        data={statesData.NH}
        states={NHStates}
        stateFullName="New Hampshire"
        selectedState="NH"
      />
    </>
  }
/>

<Route
  path="/breweries/table/MA"
  element={
    <>
      <Helmet>
        <title>Table of Massachusetts Breweries - Craftbevia</title>
        <meta
          name="description"
          content="View a table of breweries in Massachusetts. Access information about the craft beer culture in the Bay State."
        />
      </Helmet>
      <TableView
        data={statesData.MA}
        states={MAStates}
        stateFullName="Massachusetts"
        selectedState="MA"
      />
    </>
  }
/>

<Route
  path="/breweries/table/CT"
  element={
    <>
      <Helmet>
        <title>Table of Connecticut Breweries - Craftbevia</title>
        <meta
          name="description"
          content="View a table of breweries in Connecticut. Access details about unique brews in the Constitution State."
        />
      </Helmet>
      <TableView
        data={statesData.CT}
        states={CTStates}
        stateFullName="Connecticut"
        selectedState="CT"
      />
    </>
  }
/>

<Route
  path="/breweries/table/RI"
  element={
    <>
      <Helmet>
        <title>Table of Rhode Island Breweries - Craftbevia</title>
        <meta
          name="description"
          content="View a table of breweries in Rhode Island. Explore information about craft beer by the ocean in the Ocean State."
        />
      </Helmet>
      <TableView
        data={statesData.RI}
        states={RIStates}
        stateFullName="Rhode Island"
        selectedState="RI"
      />
    </>
  }
/>

<Route
  path="/breweries/table/ME"
  element={
    <>
      <Helmet>
        <title>Table of Maine Breweries - Craftbevia</title>
        <meta
          name="description"
          content="View a table of breweries in Maine. Access details about the vibrant craft beer scene in the Pine Tree State."
        />
      </Helmet>
      <TableView
        data={statesData.ME}
        states={MEStates}
        stateFullName="Maine"
        selectedState="ME"
      />
    </>
  }
/>

          {/* Add a route for the BreweryDetail page */}
          <Route path="/breweries/:ID" element={<BreweryDetail craftbevia={craftbeviaData} />} />

        </Routes>
        {/* Render the footer */}
        <Footer />
        </ThemeProvider>
      </div>
    </Router>
    </HelmetProvider>
  );
};

export default App;