import React from 'react';
import BreweryTable from './BreweryTable';
import { Box } from '@mui/material';


const TableView = ({ data, stateFullName  }) => {
  return (
    <Box sx={{ gcolor: (theme) => theme.palette.grey[200]}}>
      <BreweryTable data={data} stateFullName={stateFullName} />
    </Box>
  );
};

export default TableView;
