import React, { useState } from 'react';
import { Typography, Grid} from '@mui/material';
import craftbeviaData from '../data/brewery_NewEngland.json'; // Import the JSON data
import FilterSection from './FilterSection';
import PaginationSection from './PaginationSection';
import DataListSection from './DataListSection';

const ITEMS_PER_PAGE = 30; // Number of data to show per page

const ListView = ({ states, stateFullName }) => {
  // Initial filter state
  const initialFilters = {
    petFriendly: false,
    foodAvailability: false,
    familyFriendly: false,
  };

  // State for storing selected filters
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  // Regex patterns for filtering
  const petFriendlyRegex = /^YES(?:\s+\(.*\))?$/i;
  const foodAvailabilityRegex = /^YES$/i;
  const familyFriendlyRegex = /^YES$/i;

  // Filter data based on selected filters
  const filteredData = craftbeviaData.filter((data) => {
    return (
      (!selectedFilters.foodAvailability ||
        foodAvailabilityRegex.test(data.foodAvailability)) &&
      (!selectedFilters.familyFriendly ||
        familyFriendlyRegex.test(data.familyFriendly)) &&
      (!selectedFilters.petFriendly ||
        petFriendlyRegex.test(data.petFriendly)) &&
      states.includes(data.State) // Filter based on specified states
    );
  });

  const [currentPage, setCurrentPage] = useState(1); // State for current page

  // Calculate the index of the first and last data to show on the current page
  const indexOfLastData = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstData = indexOfLastData - ITEMS_PER_PAGE;

  // Slice the data based on the current page
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData);

  // Function to handle switch change
  const handleSwitchChange = (filterName) => (event) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: event.target.checked,
    }));
  };

  // Function to handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Function to go to the previous page
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Function to go to the next page
  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(filteredData.length / ITEMS_PER_PAGE))
    );
  };

  // Function to calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  return (
    <div>
      <Typography variant="h2" align="center" my={2}>
        {stateFullName} Breweries
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FilterSection selectedFilters={selectedFilters} handleSwitchChange={handleSwitchChange} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" align="center" my={2}>
            Total Breweries: {filteredData.length} {filteredData.length === 1 ? 'Brewery' : 'Breweries'}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <DataListSection currentData={currentData} />
        </Grid>

        <Grid item xs={12}>
          <PaginationSection
            currentPage={currentPage}
            totalPages={totalPages}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handlePageChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ListView;