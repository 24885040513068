import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useTheme } from '@mui/material/styles';

const PaginationSection = ({ currentPage, totalPages, handlePreviousPage, handleNextPage, handlePageChange }) => {
  const theme = useTheme(); // Get the current theme

  return (
    <Box display="flex" justifyContent="center" alignItems="center" my={2}>
      <ArrowLeftIcon
        onClick={handlePreviousPage}
        style={{ cursor: 'pointer', fontSize: '1.5rem', marginRight: '0.5rem' }}
      />
      {Array.from({ length: totalPages }).map((_, index) => (
        <Typography
          key={index}
          variant="body1"
          style={{
            cursor: 'pointer',
            fontWeight: index + 1 === currentPage ? 'bold' : 'normal',
            color: index + 1 === currentPage ? theme.palette.primary.main : 'inherit',
            marginRight: '0.5rem',
          }}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </Typography>
      ))}
      <ArrowRightIcon
        onClick={handleNextPage}
        style={{ cursor: 'pointer', fontSize: '1.5rem', marginLeft: '0.5rem' }}
      />
    </Box>
  );
};

export default PaginationSection;