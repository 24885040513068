import React from "react";
import { Box, Container, Grid, Typography, Button, Link as MuiLink, useTheme, useMediaQuery } from '@mui/material';
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Link } from 'react-router-dom'; // Import Link component

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen is small

  return (
    // Footer container with styling
    <Box sx={{ mt: 3, bgcolor: (theme) => theme.palette.grey[200], p: 3 }}>
      <Container maxWidth="lg">
        {/* Grid layout with two rows */}
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">

          {/* Top Row */}
          <Grid item xs={12} md={4}>
            {/* Craftbevia title with a link to the home page */}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: isSmallScreen ? 'center' : 'left' }}>
              <Link to="/" style={{ textDecoration: 'none' }}>
                Craftbevia
              </Link>
            </Typography>
          </Grid>

          {isSmallScreen ? (
            // Render navigation buttons for small screens
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <Button color="inherit" component={Link} to="/disclaimer">
                  Disclaimer
                </Button>
                <Button color="inherit" component={Link} to="/privacy">
                  Privacy Policy
                </Button>
                <Button color="inherit" component={Link} to="/terms">
                  Terms of Use
                </Button>
              </Box>
            </Grid>
          ) : (
            // Render navigation buttons for larger screens
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button color="inherit">
                  <Link to="/disclaimer" style={{ textDecoration: 'none' }}>
                    Disclaimer
                  </Link>
                </Button>
                <Button color="inherit">
                  <Link to="/privacy" style={{ textDecoration: 'none' }}>
                    Privacy Policy
                  </Link>
                </Button>
                <Button>
                  <Link to="/terms" style={{ textDecoration: 'none' }}>
                    Terms of Use
                  </Link>
                </Button>
              </Box>
            </Grid>
          )}

          {/* Bottom Row */}
          <Grid item xs={12} md={isSmallScreen ? 12 : 6}>
            {/* Copyright notice with a link to the home page */}
            <Typography variant="body2" color="text.secondary" align={isSmallScreen ? "center" : "left"}>
              {"Copyright © "}
              <Link color="inherit" to="/" style={{ textDecoration: 'none' }}>
                Craftbevia
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Grid>
          <Grid item xs={12} md={isSmallScreen ? 12 : 6}>
            {/* Social media icons with links */}
            <Box
              sx={{
                display: "flex",
                justifyContent: isSmallScreen ? "center" : "flex-end",
                "& > :not(:last-child)": {
                  marginRight: isSmallScreen ? 2 : 0, // Adjust the spacing as needed
                },
              }}
            >
              <MuiLink href="https://www.facebook.com/profile.php?id=100095246258507" color="inherit">
                <Facebook />
              </MuiLink>
              <MuiLink href="https://www.instagram.com/craftbevia/" color="inherit">
                <Instagram />
              </MuiLink>
              <MuiLink href="https://twitter.com/craftbevia" color="inherit">
                <Twitter />
              </MuiLink>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
