import React, { useState, useEffect } from 'react';
import { Box, Switch, Typography, useMediaQuery } from '@mui/material';
import brewData from '../data/brewery_NewEngland.json'; // Import the JSON data
import InteractiveMap from './InteractiveMap';
import mapConfigs from './mapConfigs';
import InteractiveMapList from './InteractiveMapList';
import geolocation from 'geolocation';

const MapView = ({ states, stateFullName, selectedState }) => {
  // Initial filter state
  const initialFilters = {
    petFriendly: false,
    foodAvailability: false,
    familyFriendly: false,
  };

  // State for controlling the delay before displaying markers
  const [showMarkers, setShowMarkers] = useState(false);

  // Handle switch change using function syntax
  const handleSwitchChangeWithDelay = (filterName) => (event) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: event.target.checked,
    }));

    // Show markers after a delay of 0.5 seconds
    setShowMarkers(false); // Reset showMarkers to false
    setTimeout(() => {
      setShowMarkers(true);
    }, 500); // 5000 milliseconds (0.5 seconds)
  };
  // State for storing selected filters
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  // Regular expression pattern to match "YES", "YES1", or "MAYBE"
  const petFriendlyRegex = /^YES(?:\s+\(.*\))?$/i;
  const foodAvailabilityRegex = /^YES$/i;
  const familyFriendlyRegex = /^YES$/i;

  // Filter breweries based on selected filters and state
  const filteredData = brewData.filter((brewery) => {
    const matchesPetFilter =
      !selectedFilters.petFriendly ||
      petFriendlyRegex.test(brewery.petFriendly);
    const matchesFoodFilter =
      !selectedFilters.foodAvailability ||
      foodAvailabilityRegex.test(brewery.foodAvailability);
    const matchesFamilyFilter =
      !selectedFilters.familyFriendly ||
      familyFriendlyRegex.test(brewery.familyFriendly);

    // Include breweries that match any filter if no filters are selected
    if (!selectedFilters.petFriendly && !selectedFilters.foodAvailability && !selectedFilters.familyFriendly) {
      return states.includes(brewery.State);
    }

    // Combine filter conditions for selected switches
    const combinedFilter =
      (!selectedFilters.petFriendly || matchesPetFilter) &&
      (!selectedFilters.foodAvailability || matchesFoodFilter) &&
      (!selectedFilters.familyFriendly || matchesFamilyFilter);

    return combinedFilter && states.includes(brewery.State);
  });

  // Get the map configuration for the selected state
  const selectedStateConfig = mapConfigs[selectedState.toLowerCase()];

  // State for storing user's current location
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 }); // Default location (0, 0)

  // Function to get user's current location using geolocation API
  useEffect(() => {
    geolocation.getCurrentPosition((error, position) => {
      if (!error) {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }
    });
  }, []);

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div>
      <Box
        padding={2}
        border={1} // Add the blue border
        borderColor="primary.main" // Specify the blue color
        textAlign="center" // Center the content horizontally
      >
        <Typography variant="h2">{stateFullName} Breweries</Typography>

        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'} // Vertically on small screens, horizontally on others
          alignItems="center"
          justifyContent="center" // Center the filters horizontally
          my={2}
          gap={2}
        >
          {/* Pet Friendly filter switch */}
          <Box display="flex" alignItems="center">
            <label>Pet Friendly:</label>
            <Switch
              checked={selectedFilters.petFriendly}
              onChange={handleSwitchChangeWithDelay('petFriendly')}
              inputProps={{ 'aria-label': 'Pet Friendly filter' }}
            />
          </Box>

          {/* Food Availability filter switch */}
          <Box display="flex" alignItems="center">
            <label>Food Availability:</label>
            <Switch
              checked={selectedFilters.foodAvailability}
              onChange={handleSwitchChangeWithDelay('foodAvailability')}
              inputProps={{ 'aria-label': 'Food Availability filter' }}
            />
          </Box>

          {/* Family Friendly filter switch */}
          <Box display="flex" alignItems="center">
            <label>Family Friendly:</label>
            <Switch
              checked={selectedFilters.familyFriendly}
              onChange={handleSwitchChangeWithDelay('familyFriendly')}
              inputProps={{ 'aria-label': 'Family Friendly filter' }}
            />
          </Box>
        </Box>

        {/* Display the total count of records */}
        <Typography variant="h5">
          Total Breweries: {filteredData.length}{' '}
          {filteredData.length === 1 ? 'Brewery' : 'Breweries'}
        </Typography>
      </Box>

      {/* Render the filtered breweries */}
      <InteractiveMap
        data={filteredData}
        center={selectedStateConfig.center}
        zoom={selectedStateConfig.zoom}
        states={states}
        showMarkers={showMarkers} // Pass showMarkers state
      />

      {/* Render the BreweryList */}
      <Box my={2} mx={2}>
        <InteractiveMapList breweries={filteredData} userLocation={userLocation} />
      </Box>
    </div>
  );
};

export default MapView;
