import React from 'react';
import { Box, Grid } from '@mui/material';
import IndividualCard from './IndividualCard';

const DataListSection = ({ currentData }) => {
  return (
    <Box mx={2}>
      <Grid container spacing={2} justifyContent="center">
        {currentData.map((dataItem) => (
          <Grid item xs={12} sm={6} md={4} key={dataItem.ID}>
            <IndividualCard data={dataItem} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DataListSection;