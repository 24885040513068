import React from "react";
import { Container, Typography } from "@mui/material";

const TermsOfUse = () => {
  return (
    // Container to limit the width of the content and add spacing from top
    <Container maxWidth="md" sx={{ mt: 3 }}>
      {/* Terms of Use title */}
      <Typography variant="h2" gutterBottom>
        Terms of Use
      </Typography>

      {/* Effective date */}
      <Typography variant="body1" paragraph>
        Effective Date: July 2023
      </Typography>

      {/* Terms of Use content */}
      <Typography variant="body1" paragraph>
        Welcome to Craftbevia. By accessing and using our comprehensive brewery database service, you agree to abide by the following Terms of Use. Please read these terms carefully before using our website.
      </Typography>

      {/* Age Restriction */}
      <Typography variant="body1" paragraph>
        1. Age Restriction
        <br />
        This website is intended for use by individuals who are 21 years of age or older. By accessing and using our services, you confirm that you are of legal drinking age in your jurisdiction.
      </Typography>

      {/* Acceptance of Terms */}
      <Typography variant="body1" paragraph>
        2. Acceptance of Terms
        <br />
        By accessing or using our services, you acknowledge that you have read, understood, and agreed to these Terms of Use. If you do not agree with any part of these terms, you may not use our website.
      </Typography>

      {/* Comprehensive Brewery Database */}
      <Typography variant="body1" paragraph>
        3. Data Accuracy
        <br />
        Our website provides a database of breweries in the United States. While we strive to ensure the accuracy of information, please be aware that brewery details, policies, and data may change over time. Users are advised to verify information directly with the respective breweries before making decisions.
      </Typography>

      {/* Intellectual Property */}
      <Typography variant="body1" paragraph>
        4. Intellectual Property
        <br />
        All content on our website, including text, images, logos, and other materials, is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, or use any content from our website without explicit permission.
      </Typography>

      {/* Third-Party Links and Content */}
      <Typography variant="body1" paragraph>
        5. Third-Party Links and Content
        <br />
        Our website may contain links to third-party websites and may display content provided by third parties. We do not endorse, control, or take responsibility for the content of external sites. Users are encouraged to review the terms and policies of those external sites. 
      </Typography>

      {/* Use of Third-Party Services */}
      <Typography variant="body1" paragraph>
        6. Use of Third-Party Services
        <br />
        Our website may use third-party services. By using our services, you acknowledge and agree that your information may be shared with and processed by these third-party services for marketing communications and related purposes.
      </Typography>

      {/* Location-Based Data Collection */}
      <Typography variant="body1" paragraph>
        7. Location-Based Data Collection
        <br />
        To enhance your user experience, we may collect and use your location data when you use our website. By using our services, you consent to the collection and use of your location information for this purpose.
      </Typography>
      {/* Use Restrictions */}
      <Typography variant="body1" paragraph>
        8. Use Restrictions
        <br />
        You agree not to:
        <br />
        a. Use our website for any illegal, unauthorized, or improper purposes.
        <br />
        b. Attempt to gain unauthorized access to any part of our website, servers, or databases.
        <br />
        c. Interfere with the proper functioning of our website or disrupt its services.
        <br />
        d. Use any automated tools, bots, or scripts to access or collect data from our website.
      </Typography>

      {/* Limitation of Liability */}
      <Typography variant="body1" paragraph>
        9. Limitation of Liability
        <br />
        We strive to provide accurate and reliable information; however, we cannot guarantee the accuracy, completeness, or timeliness of the data displayed on our website. You acknowledge that your use of our services is at your own risk, and we are not liable for any damages or losses resulting from your reliance on our information.
      </Typography>

      {/* Indemnification */}
      <Typography variant="body1" paragraph>
        10. Indemnification
        <br />
        You agree to indemnify and hold us harmless from any claims, damages, losses, liabilities, costs, and expenses arising from your use of our website, violation of these Terms of Use, or infringement of any rights.
      </Typography>

      {/* Changes to the Terms */}
      <Typography variant="body1" paragraph>
        11. Changes to the Terms
        <br />
        We reserve the right to modify these Terms of Use at any time. Any updates will be effective upon posting the revised version on our website. Your continued use of our services after such changes constitutes acceptance of the updated Terms of Use.
      </Typography>

      {/* Contact Information */}
      <Typography variant="body1" paragraph>
        12. Contact Us
        <br />
        If you have any questions, concerns, or feedback regarding our Terms of Use, please contact us at craftbevia@gmail.com.
      </Typography>
    </Container>
  );
};

// Export the TermsOfUse component
export default TermsOfUse;