import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  IconButton,
  useTheme,
  Tooltip,
  useMediaQuery,
  Container,
} from '@mui/material';
import SocialMediaIcons from './SocialMediaIcons';
import ContactIcons from './ContactIcons';
import {
  Deck,
  TableRestaurant,
  Restaurant,
  Tapas,
  AirportShuttle,
  DeliveryDining,
  Pets,
  FamilyRestroom,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

const IndividualCard = ({ data }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card sx={{ width: '100%', flexGrow: 1, marginBottom: 1, border: `1px solid ${theme.palette.primary.main}` }}>
      <CardContent>
        <Box>
          <div style={{ textAlign: 'center' }}>
            {/* Create a Link around the name */}
            <Link to={`/breweries/${data.ID}`} style={{ textDecoration: 'none' }}>
              <Typography variant="h5" fontWeight="bold">
                {data.Name}
              </Typography>
            </Link>
          </div>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems={isSmallScreen ? 'center' : 'flex-start'} mt={2}>
        <Box
  style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: isSmallScreen ? 'center' : 'left', // Center on small screens, left-align on large screens
  }}
>
{!isSmallScreen && (
  <Typography variant="h6" color="textSecondary" gutterBottom sx={{ textAlign: 'center' }}>
    {data.Street}
    <br />
    {data.City}, {data.State} {data.zipCode}
  </Typography>
    )}
</Box>


          {!isSmallScreen && (
            <Stack>
              <SocialMediaIcons
                instagram={data.Instagram}
                facebook={data.Facebook}
                twitter={data.Twitter}
                iconSize={32}
              />
              <ContactIcons
                email={data.Email}
                phoneNumber={data.phoneNumber}
                website={data.Website}
                iconSize={32}
              />
            </Stack>
          )}
        </Box>

        {isSmallScreen ? (
          <div style={{ textAlign: 'center' }}>
            {/* Row 0: Address */}
            <Container maxWidth="sm" >
            <Typography variant="h6" color="textSecondary" gutterBottom sx={{ textAlign: 'center' }}>
              {data.Street}
              <br />
              {data.City}, {data.State} {data.zipCode}
            </Typography>
            </Container>
            
            {/* Row 1: Social media icons */}
            <Container maxWidth="sm" >
              <Stack direction="row" spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                <SocialMediaIcons
                  instagram={data.Instagram}
                  facebook={data.Facebook}
                  twitter={data.Twitter}
                  iconSize={32}
                />
              </Stack>
            </Container>

            {/* Row 2: Phone, email, and website icons */}
            <Container maxWidth="sm">
              <Stack direction="row" spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                <ContactIcons
                  email={data.Email}
                  phoneNumber={data.phoneNumber}
                  website={data.Website}
                  iconSize={32}
                />
              </Stack>
            </Container>

            {/* Row 3: Indoor seating, outdoor seating, dog friendly, and family friendly icons */}
            <Container maxWidth="sm">
              <Stack direction="row" spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                {data.indoorSeating === 'YES' ? (
                  <Tooltip title="Indoor Seating">
                    <IconButton color="primary">
                      <TableRestaurant />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Indoor Seating">
                    <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                      <TableRestaurant />
                    </IconButton>
                  </Tooltip>
                )}
                {data.outdoorSeating === 'YES' ? (
                  <Tooltip title="Outdoor Seating">
                    <IconButton color="primary">
                      <Deck />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Outdoor Seating">
                    <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                      <Deck />
                    </IconButton>
                  </Tooltip>
                )}
                {data.petFriendly === 'YES' || data.petFriendly === 'YES (outdoors)' || data.petFriendly === 'YES (indoors)' ? (
                  <Tooltip title="Pet Friendly">
                    <IconButton color="primary">
                      <Pets />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Pet Friendly">
                    <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                      <Pets />
                    </IconButton>
                  </Tooltip>
                )}
                {data.familyFriendly === 'YES' ? (
                  <Tooltip title="Family Friendly">
                    <IconButton color="primary">
                      <FamilyRestroom />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Family Friendly">
                    <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                      <FamilyRestroom />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Container>

            {/* Row 4: The remaining four icons */}
            <Container maxWidth="sm">
              <Stack direction="row" spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                {data.kitchenAvailability === 'YES' ? (
                  <Tooltip title="Kitchen">
                    <IconButton color="primary">
                      <Restaurant />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Kitchen">
                    <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                      <Restaurant />
                    </IconButton>
                  </Tooltip>
                )}
                {data.snacksAvailability === 'YES' ? (
                  <Tooltip title="Snacks">
                    <IconButton color="primary">
                      <Tapas />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Snacks">
                    <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                      <Tapas />
                    </IconButton>
                  </Tooltip>
                )}
                {data.foodTruckAvailability === 'YES' ? (
                  <Tooltip title="Food Trucks/Vendor">
                    <IconButton color="primary">
                      <AirportShuttle />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Food Trucks/Vendor">
                    <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                      <AirportShuttle />
                    </IconButton>
                  </Tooltip>
                )}
                {data.bringYourOwnFoodFriendly === 'YES' ? (
                  <Tooltip title="Bring Your Own Food Friendly">
                    <IconButton color="primary">
                      <DeliveryDining />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Bring Your Own Food Friendly">
                    <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                      <DeliveryDining />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Container>
          </div>
        ) : (
          // Large screen layout (unchanged)
          <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
            {/* Indoor Seating Icon */}
            {data.indoorSeating === 'YES' ? (
              <Tooltip title="Indoor Seating">
                <IconButton color="primary">
                  <TableRestaurant />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Indoor Seating">
                <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                  <TableRestaurant />
                </IconButton>
              </Tooltip>
            )}

            {/* Outdoor Seating Icon */}
            {data.outdoorSeating === 'YES' ? (
              <Tooltip title="Outdoor Seating">
                <IconButton color="primary">
                  <Deck />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Outdoor Seating">
                <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                  <Deck />
                </IconButton>
              </Tooltip>
            )}

            {/* Pet Friendly Icon */}
            {data.petFriendly === 'YES' || data.petFriendly === 'YES (outdoors)' || data.petFriendly === 'YES (indoors)' ? (
              <Tooltip title="Pet Friendly">
                <IconButton color="primary">
                  <Pets />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Pet Friendly">
                <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                  <Pets />
                </IconButton>
              </Tooltip>
            )}

            {/* Family Friendly Icon */}
            {data.familyFriendly === 'YES' ? (
              <Tooltip title="Family Friendly">
                <IconButton color="primary">
                  <FamilyRestroom />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Family Friendly">
                <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                  <FamilyRestroom />
                </IconButton>
              </Tooltip>
            )}

            {/* Restaurant Icon */}
            {data.kitchenAvailability === 'YES' ? (
              <Tooltip title="Kitchen">
                <IconButton color="primary">
                  <Restaurant />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Kitchen">
                <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                  <Restaurant />
                </IconButton>
              </Tooltip>
            )}

            {/* Tapas Icon */}
            {data.snacksAvailability === 'YES' ? (
              <Tooltip title="Snacks">
                <IconButton color="primary">
                  <Tapas />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Snacks">
                <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                  <Tapas />
                </IconButton>
              </Tooltip>
            )}

            {/* AirportShuttle Icon */}
            {data.foodTruckAvailability === 'YES' ? (
              <Tooltip title="Food Trucks/Vendor">
                <IconButton color="primary">
                  <AirportShuttle />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Food Trucks/Vendor">
                <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                  <AirportShuttle />
                </IconButton>
              </Tooltip>
            )}

            {/* DeliveryDining Icon */}
            {data.bringYourOwnFoodFriendly === 'YES' ? (
              <Tooltip title="Bring Your Own Food Friendly">
                <IconButton color="primary">
                  <DeliveryDining />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Bring Your Own Food Friendly">
                <IconButton color="primary" sx={{ color: (theme) => theme.palette.grey[500] }}>
                  <DeliveryDining />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default IndividualCard;
