import React from 'react';
import { Typography, Box } from '@mui/material';

const MainPageHeader = () => {
  return (
    <Box textAlign="center" marginTop={2} marginBottom={2}>
      <Typography variant="h2" fontWeight="bold" gutterBottom>
        Craftbevia
      </Typography>
      <Typography variant="h6" color="textSecondary">
        Discover the wonder of craft beverages!
      </Typography>
    </Box>
  );
};

export default MainPageHeader;