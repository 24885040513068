import React, { useState } from 'react';
import MainPageHeader from './MainPageHeader';
import StateCards from './StateCards';
import AgeVerificationPopup from '../General/AgeVerificationPopup'; // Import the updated AgeVerificationPopup component

const MainPage = () => {
  const [ageVerificationOpen, setAgeVerificationOpen] = useState(true);

  const handleAgeSubmit = () => {
    setAgeVerificationOpen(false);
  };

  return (
    <div>
      <MainPageHeader />
      <StateCards />

      {/* Render the Age Verification Popup */}
      <AgeVerificationPopup
        open={ageVerificationOpen}
        onAgeSubmit={handleAgeSubmit}
      />
    </div>
  );
};

export default MainPage;