import React from 'react';
import { Tooltip } from '@mui/material';
import { Instagram, Facebook, Twitter } from '@mui/icons-material';

const SocialMediaIcons = ({ instagram, facebook, twitter, iconSize }) => {
  const iconStyle = { fontSize: iconSize || 32 };

  const isNotNull = (value) => value !== 'NULL' && value !== undefined;

  return (
    <div>
      {isNotNull(instagram) ? (
        <Tooltip title="Instagram">
        <a href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noopener noreferrer">
          <Instagram style={iconStyle} />
        </a>
        </Tooltip>
      ) : (
        <Tooltip title="Instagram">
        <Instagram style={{ ...iconStyle, color: 'grey' }} />
        </Tooltip>
      )}

      {isNotNull(facebook) ? (
        <Tooltip title="Facebook">
        <a href={`https://www.facebook.com/${facebook}`} target="_blank" rel="noopener noreferrer">
          <Facebook style={iconStyle} />
        </a>
        </Tooltip>
      ) : (
        <Tooltip title="Facebook">
        <Facebook style={{ ...iconStyle, color: 'grey' }} />
        </Tooltip>
      )}

      {isNotNull(twitter) ? (
        <Tooltip title="Twitter">
        <a href={`https://www.twitter.com/${twitter}`} target="_blank" rel="noopener noreferrer">
          <Twitter style={iconStyle} />
        </a>
        </Tooltip>
      ) : (
        <Tooltip title="Twitter">
        <Twitter style={{ ...iconStyle, color: 'grey' }} />
        </Tooltip>
      )}
    </div>
  );
};

export default SocialMediaIcons;
