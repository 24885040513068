import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { Box, Paper, Typography, Button } from '@mui/material';
import geolocation from 'geolocation';

const mapContainerStyle = {
  height: '600px',
  width: '950%',
};

const InteractiveMap = ({ data, center, zoom, showMarkers }) => {
  const [userLocation, setUserLocation] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    geolocation.getCurrentPosition((error, position) => {
      if (!error) {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }
    });
  }, []);
  
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleInfoWindowCloseClick = () => {
    setSelectedMarker(null);
  };

  const svgToDataURL = (svgCode) => {
    const svgBlob = new Blob([svgCode], { type: 'image/svg+xml' });
    const svgURL = URL.createObjectURL(svgBlob);
    return svgURL;
  };

  const openGoogleMaps = () => {
    if (selectedMarker) {
      const { Latitude, Longitude } = selectedMarker[0];
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${Latitude},${Longitude}`;
      window.open(googleMapsUrl, '_blank');
    }
  };

  const myLocationSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#2196F3">
      <path d="M12 2C7.03 2 3 6.03 3 11c0 5.25 9 13 9 13s9-7.75 9-13c0-4.97-4.03-9-9-9zm0 18c-2.67 0-5.19-1.03-7.07-2.93C4.56 16.18 4 13.67 4 11c0-3.86 3.14-7 7-7s7 3.14 7 7c0 2.67-.57 5.18-1.93 7.07C17.19 18.97 14.67 20 12 20z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
  `;

  const myLocationDataURL = svgToDataURL(myLocationSvg);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <Box display="flex" justifyContent="center">
        <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={zoom}>
          {userLocation && (
            <Marker
              position={{ lat: userLocation.lat, lng: userLocation.lng }}
              icon={{
                url: myLocationDataURL,
                scaledSize: new window.google.maps.Size(40, 40),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(20, 20),
              }}
              animation={window.google.maps.Animation.BOUNCE}
              sx={{
                animation: (theme) => `${theme.transitions.create('transform', {
                  easing: theme.transitions.easing.easeInOut,
                  duration: theme.transitions.duration.short,
                })} infinite alternate`,
              }}
            />
          )}
          {showMarkers &&
        data.map((brewery) => (
          <Marker
            key={brewery.ID}
            position={{ lat: brewery.Latitude, lng: brewery.Longitude }}
            onClick={() => handleMarkerClick(brewery)}
          />
        ))}
          {selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.Latitude, lng: selectedMarker.Longitude }}
              onCloseClick={handleInfoWindowCloseClick}
            >
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6">{selectedMarker.Name}</Typography>
                <Typography variant="body1">{selectedMarker.Address}</Typography>
                <Button variant="contained" onClick={openGoogleMaps}>
                  View on Google Maps
                </Button>
              </Paper>
            </InfoWindow>
          )}
        </GoogleMap>
      </Box>
    </LoadScript>
  );
};

export default InteractiveMap;
