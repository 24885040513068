import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { geoCentroid } from 'd3-geo'; // Import geoCentroid function
import { Link } from 'react-router-dom';

import allStates from '../data/allstates.json';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

const stateIdsToShow = ['VT'];

const offsets = {
  VT: [50, -8],
};

const VermontMap = () => {
  const [hoveredState, setHoveredState] = useState(null);

  return (
    <ComposableMap
      projection="geoAlbers"
      projectionConfig={{
        rotate: [71.057, 0],
        scale: 13000,
        center: [-1.5, 43.9],
      }}
      style={{ width: '100%', height: '100%' }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <>
            {geographies.map((geo) => {
              const cur = allStates.find((s) => s.val === geo.id);
              const isStateToShow = stateIdsToShow.includes(cur.id);

              if (!isStateToShow) {
                return null; // Skip rendering this state
              }

              const [dx, dy] = offsets[cur.id] || [0, 0];

              // Calculate the centroid manually using d3-geo
              const centroid = geoCentroid(geo);
              const [cx, cy] = centroid;

              return (
                <Link
                  key={geo.rsmKey}
                  to={`/`}
                  style={{ textDecoration: 'none', outline: 'none' }}
                >
                  <Geography
                    stroke="#FFF"
                    geography={geo}
                    fill={hoveredState === cur.id ? '#2196f3' : isStateToShow ? '#DDD' : 'none'}
                    onMouseEnter={() => setHoveredState(cur.id)}
                    onMouseLeave={() => setHoveredState(null)}
                  />
                  {/* Add the identifier text */}
                  <text
                    x={cx + dx} // Use cx as x-coordinate
                    y={cy + dy} // Use cy as y-coordinate
                    fontSize={12}
                    textAnchor="middle"
                  >
                    {cur.id}
                  </text>
                </Link>
              );
            })}
          </>
        )}
      </Geographies>
    </ComposableMap>
  );
};

export default VermontMap;
