import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ContactPopup from './ContactPopup';

const NavBar = () => {
  const [breweryMenuOpen, setBreweryMenuOpen] = useState(false);
  const [breweryAnchorEl, setBreweryAnchorEl] = useState(null);
  const [contactPopupOpen, setContactPopupOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen is mobile

  const handleBreweryMenuOpen = (event) => {
    setBreweryAnchorEl(event.currentTarget);
    setBreweryMenuOpen(true);
  };

  const handleBreweryMenuClose = () => {
    setBreweryAnchorEl(null);
    setBreweryMenuOpen(false);
  };

  const handleContactButtonClick = () => {
    setContactPopupOpen(true);
  };

  const handleContactPopupClose = () => {
    setContactPopupOpen(false);
  };

  return (
    <AppBar position="static" sx={{ height: '64px' }}>
      <Toolbar>
        <Box display="flex" alignItems="center" flexGrow={1}>
          {/* Company Logo */}
          <img
            src="/craftbevia_new_nav.png"
            alt="Company Logo"
            style={{
              width: isMobile ? '150px' : '200px', // Adjust logo size for mobile
              marginRight: '10px',
              padding: 0,
            }}
          />
        </Box>

        {isMobile ? ( // Render mobile menu for small screens
          <>
            <Button
              color="inherit"
              onClick={handleBreweryMenuOpen}
              onMouseOver={handleBreweryMenuOpen}
            >
              Menu
            </Button>
            <Menu
              anchorEl={breweryAnchorEl}
              open={breweryMenuOpen}
              onClose={handleBreweryMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {/* Include Home and Contact options in the mobile menu */}
              <MenuItem component={Link} to="/" onClick={handleBreweryMenuClose}>
                Home
              </MenuItem>
              <MenuItem
                component={Link}
                to="/breweries/list/newengland"
                onClick={handleBreweryMenuClose}
              >
                NE Brewery List
              </MenuItem>
              <MenuItem
                component={Link}
                to="/breweries/map/newengland"
                onClick={handleBreweryMenuClose}
              >
                NE Brewery Map
              </MenuItem>
              <MenuItem
                component={Link}
                to="/breweries/table/newengland"
                onClick={handleBreweryMenuClose}
              >
                NE Brewery Table
              </MenuItem>
              <MenuItem onClick={handleContactButtonClick}>Contact</MenuItem>
            </Menu>
          </>
        ) : (
          // Render desktop menu for larger screens
          <>
            {/* Home button */}
            <Box>
              <Button color="inherit">
                <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
                  Home
                </Link>
              </Button>
            </Box>

            {/* Brewery menu with submenu items */}
            <Button
              color="inherit"
              onClick={handleBreweryMenuOpen}
              onMouseOver={handleBreweryMenuOpen}
            >
              Brewery
            </Button>
            <Menu
              anchorEl={breweryAnchorEl}
              open={breweryMenuOpen}
              onClose={handleBreweryMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {/* Submenu items with links */}
              <MenuItem
                component={Link}
                to="/breweries/list/newengland"
                onClick={handleBreweryMenuClose}
              >
                New England Brewery List
              </MenuItem>
              <MenuItem
                component={Link}
                to="/breweries/map/newengland"
                onClick={handleBreweryMenuClose}
              >
                New England Brewery Map
              </MenuItem>
              <MenuItem
                component={Link}
                to="/breweries/table/newengland"
                onClick={handleBreweryMenuClose}
              >
                New England Brewery Table
              </MenuItem>
            </Menu>

            {/* Contact button */}
            <Button color="inherit" onClick={handleContactButtonClick}>
              Contact
            </Button>
          </>
        )}

        {/* Render the Contact Popup */}
        <ContactPopup open={contactPopupOpen} onClose={handleContactPopupClose} />
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
