import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

// ContactPopup component function with props 'open' and 'onClose'
const ContactPopup = ({ open, onClose }) => {
  return (
    // Dialog component from MUI, controlled by 'open' prop and 'onClose' event handler
    <Dialog open={open} onClose={onClose}>
      {/* DialogTitle component */}
      <DialogTitle>Contact Us</DialogTitle>
      {/* DialogContent component */}
      <DialogContent>
        {/* DialogContentText component */}
        {/* Text content for the contact popup */}
        <DialogContentText>
          If you are aware of any inaccuracies or outdated information, please don't hesitate to{" "}
          {/* Anchor tag for email link */}
          <a href="mailto:craftbevia@gmail.com">contact us</a>. Your feedback is valuable and helps us improve the accuracy and quality of our service.
        </DialogContentText>
      </DialogContent>
      {/* DialogActions component */}
      <DialogActions>
        {/* Button component for closing the dialog */}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

// Export the ContactPopup component
export default ContactPopup;