import React from "react";
import { Container, Typography } from "@mui/material";

const Disclaimer = () => {
  return (
    // Container to limit the width of the content and add spacing from top
    <Container maxWidth="md" sx={{ mt: 3 }}>
      {/* Disclaimer title */}
      <Typography variant="h2" gutterBottom>
        Disclaimer
      </Typography>

      {/* Effective date */}
      <Typography variant="body1" paragraph>
        Effective Date: July 2023
      </Typography>

      {/* Disclaimer content */}
      <Typography variant="body1" color="text.secondary" align="left">
        The information provided on this website is for general informational purposes only. While we strive to ensure the accuracy of the data, please be aware that brewery details, policies, and data may change over time. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information contained on the website. Any reliance you place on such information is strictly at your own risk. Always verify information directly with the respective breweries before making decisions.
      </Typography>

      {/* Contact information */}
      <Typography variant="body1" color="text.secondary" align="left" mt={2}>
        If you are aware of any inaccuracies or outdated information, please don't hesitate to <a href="mailto:craftbevia@gmail.com">contact us</a>. Your feedback is valuable and helps us improve the accuracy and quality of our service.
      </Typography>
    </Container>
  );
};

// Export the Disclaimer component
export default Disclaimer;