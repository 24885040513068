import React from 'react';
import { Box, Switch, Typography, useMediaQuery } from '@mui/material';

const FilterSection = ({ selectedFilters, handleSwitchChange }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)'); // Check if it's a small screen

  return (
    <Box my={2}>
      <Typography variant="h6" align="center" gutterBottom>
        Filters
      </Typography>
      <Box
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'} // Vertical on small screens, horizontal on medium and larger screens
        alignItems={isSmallScreen ? 'center' : 'center'} // Centered vertically and horizontally on all screens
        justifyContent={isSmallScreen ? 'center' : 'center'} // Centered horizontally on all screens
        gap={isSmallScreen ? 1 : 2} // Smaller gap between filters on small screens
      >
        {/* Pet Friendly filter switch */}
        <Box display="flex" alignItems="center">
          <label>Pet Friendly:</label>
          <Switch
            checked={selectedFilters.petFriendly}
            onChange={handleSwitchChange('petFriendly')}
            inputProps={{ 'aria-label': 'Pet Friendly filter' }}
          />
        </Box>

        {/* Food Availability filter switch */}
        <Box display="flex" alignItems="center">
          <label>Food Availability:</label>
          <Switch
            checked={selectedFilters.foodAvailability}
            onChange={handleSwitchChange('foodAvailability')}
            inputProps={{ 'aria-label': 'Food Availability filter' }}
          />
        </Box>

        {/* Family Friendly filter switch */}
        <Box display="flex" alignItems="center">
          <label>Family Friendly:</label>
          <Switch
            checked={selectedFilters.familyFriendly}
            onChange={handleSwitchChange('familyFriendly')}
            inputProps={{ 'aria-label': 'Family Friendly filter' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FilterSection;