import React from 'react';
import { Grid, Card, CardContent, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import VermontMap from '../maps/VermontMap';
import MassachusettsMap from '../maps/MassachusettsMap';
import RhodeIslandMap from '../maps/RhodeIslandMap';
import ConnecticutMap from '../maps/ConnecticutMap';
import NewHampshireMap from '../maps/NewHampshireMap';
import MaineMap from '../maps/MaineMap';

// Style for the cards
const cardStyle = {
  maxWidth: 400, // Limit the maximum width of the cards
  margin: '0 auto', // Center the cards horizontally
  border: '2px solid #2196F3', // Add a border to the cards
};

const StateCards = () => {
  // Replace the stateNames and stateInfo arrays with actual data for each state
  const statesData = [
    { name: 'Vermont', code: 'VT', component: <VermontMap /> },
    { name: 'New Hampshire', code: 'NH', component: <NewHampshireMap /> },
    { name: 'Maine', code: 'ME', component: <MaineMap /> },
    { name: 'Massachusetts', code: 'MA', component: <MassachusettsMap /> },
    { name: 'Connecticut', code: 'CT', component: <ConnecticutMap /> },
    { name: 'Rhode Island', code: 'RI', component: <RhodeIslandMap /> },
  ];

  const navigate = useNavigate(); // Initialize useNavigate hook

  // Function to handle the click on the "List" button
  const handleListButtonClick = (stateCode) => {
    navigate(`/breweries/list/${stateCode}`); // Redirect to the specific list page for the state
  };

  // Function to handle the click on the "Map" button
  const handleMapButtonClick = (stateCode) => {
    navigate(`/breweries/map/${stateCode}`); // Redirect to the specific map page for the state
  };

  // Function to handle the click on the "Table" button
  const handleTableButtonClick = (stateCode) => {
    navigate(`/breweries/table/${stateCode}`); // Redirect to the specific table page for the state
  };

  return (
    <Grid container spacing={1} justifyContent="center">
      {/* Map through the statesData array and render each state's card */}
      {statesData.map((state) => (
        <Grid key={state.code} item xs={12} sm={6} md={4}>
          <Card style={cardStyle}>
            <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              {/* State Name at the top - Centered */}
              <Typography variant="h4" color="primary" component="h2" gutterBottom align="center">
                {state.name}
              </Typography>

              {/* State Map in the middle */}
              <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
                {state.component} {/* Render the state component */}
              </Box>

              {/* Three buttons at the bottom */}
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button size="large" color="primary" onClick={() => handleListButtonClick(state.code)}>
                  List
                </Button>
                <Button size="large" color="primary" onClick={() => handleMapButtonClick(state.code)}>
                  Map
                </Button>
                <Button size="large" color="primary" onClick={() => handleTableButtonClick(state.code)}>
                  Table
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default StateCards;