const mapConfigs = {
    newengland: {
      mapContainerStyle: {
        height: '600px',
        width: '950px',
      },
      center: {
        lat: 44.2033,
        lng: -70.3039,
      },
      zoom: 6,
    },
    vt: {
      mapContainerStyle: {
        height: '600px',
        width: '800px',
      },
      center: {
        lat: 44.5588,
        lng: -72.5778,
      },
      zoom: 7.25,
    },
    nh: {
      mapContainerStyle: {
        height: '600px',
        width: '800px',
      },
      center: {
        lat: 43.1939,
        lng: -72.5778,
      },
      zoom: 7.25,
    },
    ct: {
      mapContainerStyle: {
        height: '600px',
        width: '800px',
      },
      center: {
        lat: 41.6032,
        lng: -73.0877,
      },
      zoom: 9,
    },
    ma: {
        mapContainerStyle: {
          height: '600px',
          width: '800px',
        },
        center: {
          lat: 42.4072,
          lng: -71.3824,
        },
        zoom: 8,
      },
    ri: {
      mapContainerStyle: {
        height: '600px',
        width: '800px',
      },
      center: {
        lat: 41.5801,
        lng: -71.4774,
      },
      zoom: 9,
    },
    me: {
      mapContainerStyle: {
        height: '600px',
        width: '800px',
      },
      center: {
        lat: 45.2538,
        lng: -69.4455,
      },
      zoom: 7,
    },
  };
  
  export default mapConfigs;
  