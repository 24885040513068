import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';

const AgeVerificationPopup = ({ open, onAgeSubmit }) => {
  const [dateOfBirth, setDateOfBirth] = React.useState('');
  const [error, setError] = React.useState('');

  const handleDateOfBirthChange = (event) => {
    setDateOfBirth(event.target.value);
    setError('');
  };

  const handleSubmit = () => {
    const birthDate = new Date(dateOfBirth);
    const today = new Date();

    const yearsDiff = today.getFullYear() - birthDate.getFullYear();
    const isSameMonthAndDay =
      today.getMonth() === birthDate.getMonth() && today.getDate() === birthDate.getDate();

    // Compare both years and whether it's the same month and day
    if (yearsDiff > 21 || (yearsDiff === 21 && isSameMonthAndDay)) {
      // Save verification status in local storage
      localStorage.setItem('ageVerified', 'true');
      onAgeSubmit();
    } else {
      setError('You must be 21 or older to continue.');
    }
  };

  const dialogTitleStyle = {
    backgroundColor: '#1976D2',
    color: 'white',
  };

  const submitButtonStyle = {
    backgroundColor: '#1976D2',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1976D2',
    },
  };

  // Check if age has already been verified
  const isAgeVerified = localStorage.getItem('ageVerified') === 'true';

  return (
    // Show the popup only if it's not verified yet
    !isAgeVerified && (
      <Dialog open={open} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle style={dialogTitleStyle}>Age Verification</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ paddingTop: '20px' }}>
            Please enter your date of birth to continue:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            type="date"
            value={dateOfBirth}
            onChange={handleDateOfBirthChange}
            fullWidth
            error={!!error}
            helperText={error}
            InputLabelProps={{
              shrink: true, // Display label above the text box
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button style={submitButtonStyle} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default AgeVerificationPopup;