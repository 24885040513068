import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, TextField, Typography, IconButton, InputAdornment, Select, MenuItem, FormControl, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';

// Helper function to get unique states from the data
const getUniqueStates = (data) => {
  const states = new Set();
  data.forEach((data) => {
    states.add(data.State);
  });
  return Array.from(states);
};

const BreweryTable = ({ data, stateFullName }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:600px)'); // Check if it's a small screen

  const [nameFilter, setNameFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('ALL');
  const [tourFilter, setTourFilter] = useState('ALL');
  const [indoorFilter, setIndoorFilter] = useState('ALL');
  const [outdoorFilter, setOutdoorFilter] = useState('ALL');
  const [foodFilter, setFoodFilter] = useState('ALL');
  const [familyFilter, setFamilyFilter] = useState('ALL');
  const [petFilter, setPetFilter] = useState('ALL');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Get the unique states from the data for the State filter dropdown
  const uniqueStates = getUniqueStates(data);

  // Filter the data based on the filter values
  const filteredData = data.filter((data) => {
    return (
      data.Name.toLowerCase().includes(nameFilter.toLowerCase()) && // Filter by Name
      data.City.toLowerCase().includes(cityFilter.toLowerCase()) && // Filter by City
      (stateFilter === 'ALL' || data.State === stateFilter) && // Filter by State
      (tourFilter === 'ALL' || data.tourAvailability === tourFilter) && // Filter by Tour Availability
      (indoorFilter === 'ALL' || data.indoorSeating === indoorFilter) && // Filter by Indoor Seating
      (outdoorFilter === 'ALL' || data.outdoorSeating === outdoorFilter) && // Filter by Outdoor Seating
      (foodFilter === 'ALL' || data.foodAvailability === foodFilter) && // Filter by Food Availability
      (familyFilter === 'ALL' || data.familyFriendly === familyFilter) && // Filter by Family Friendly
      (petFilter === 'ALL' || data.petFriendly.includes(petFilter)) // Filter by Pet Friendly (Check if value includes either "YES" or "YES (outdoors)")
    );
  });

  // Event handler for changing the page in the pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Event handler for changing the number of rows per page in the pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Event handlers for the filter inputs
  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  const handleCityFilterChange = (event) => {
    setCityFilter(event.target.value);
  };

  const handleStateFilterChange = (event) => {
    setStateFilter(event.target.value);
  };

  const handleTourFilterChange = (event) => {
    setTourFilter(event.target.value);
  };

  const handleIndoorFilterChange = (event) => {
    setIndoorFilter(event.target.value);
  };

  const handleOutdoorFilterChange = (event) => {
    setOutdoorFilter(event.target.value);
  };

  const handleFoodFilterChange = (event) => {
    setFoodFilter(event.target.value);
  };

  const handleFamilyFilterChange = (event) => {
    setFamilyFilter(event.target.value);
  };

  const handlePetFilterChange = (event) => {
    setPetFilter(event.target.value);
  };

  // Set the state filter to 'ALL' when the component mounts
  useEffect(() => {
    setStateFilter('ALL');
  }, []);

  return (
    <Box>
      {/* Display the title with the state full name and "Breweries" */}
      <Box
        display="flex"
        justifyContent="center"
        my={2}
        gap={2}
      >
        <Typography variant="h2" align="center" my={2}>
        {stateFullName} Breweries
      </Typography>
      </Box>
      {/* Display the total count of breweries */}
      <Box
        display="flex"
        justifyContent="center"
        my={2}
      >
        <Typography variant={isSmallScreen ? 'h6' : 'h5'}>
          Total Breweries: {filteredData.length}{' '}
          {filteredData.length === 1 ? 'Brewery' : 'Breweries'}
        </Typography>
      </Box>
      <Box sx={{ padding: '0px 16px 16px' }}>
        {/* Render the table containing the breweries */}
        <TableContainer component={Paper} style={{ border: `1px solid ${theme.palette.primary.main}` }}>
          <Table>
            <TableHead>
              {/* Display the table headers */}
              <TableRow>
                <TableCell>
                  {/* Filter input for Name */}
                  <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <Typography>
                      Name
                    </Typography>
                    <TextField
                      value={nameFilter}
                      onChange={handleNameFilterChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton size="small" disabled>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  {/* Filter input for City */}
                  <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <Typography>
                      City
                    </Typography>
                    <TextField
                      value={cityFilter}
                      onChange={handleCityFilterChange}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton size="small" disabled>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell style={{ width: '100px' }}>
                  {/* Filter input for State */}
                  <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <Typography>
                      State
                    </Typography>
                    <FormControl size="small" variant="outlined">
                      <Select
                        value={stateFilter}
                        onChange={handleStateFilterChange}
                        style={{ minWidth: '80px' }}
                      >
                        {/* Display the states in the State filter dropdown */}
                        {['ALL', ...uniqueStates].map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
                {/* Continue the same pattern for other filter headers */}
                <TableCell style={{ width: '115px' }}>
                  <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <Typography>
                      Tour Availability
                    </Typography>
                    <FormControl size="small" variant="outlined">
                      <Select
                        value={tourFilter}
                        onChange={handleTourFilterChange}
                        style={{ minWidth: '80px' }}
                      >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="YES">YES</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="NULL">NULL</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
                <TableCell style={{ width: '115px' }}>
                  <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <Typography>
                      Indoor Seating
                    </Typography>
                    <FormControl size="small" variant="outlined">
                      <Select
                        value={indoorFilter}
                        onChange={handleIndoorFilterChange}
                        style={{ minWidth: '80px' }}
                      >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="YES">YES</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="NULL">NULL</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
                <TableCell style={{ width: '120px' }}>
                  <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <Typography>
                      Outdoor Seating
                    </Typography>
                    <FormControl size="small" variant="outlined">
                      <Select
                        value={outdoorFilter}
                        onChange={handleOutdoorFilterChange}
                        style={{ minWidth: '80px' }}
                      >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="YES">YES</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="NULL">NULL</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
                <TableCell style={{ width: '120px' }}>
                  <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <Typography>
                      Food Availability
                    </Typography>
                    <FormControl size="small" variant="outlined">
                      <Select
                        value={foodFilter}
                        onChange={handleFoodFilterChange}
                        style={{ minWidth: '80px' }}
                      >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="YES">YES</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="NULL">NULL</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
                <TableCell style={{ width: '115px' }}>
                  <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <Typography>
                      Family Friendly
                    </Typography>
                    <FormControl size="small" variant="outlined">
                      <Select
                        value={familyFilter}
                        onChange={handleFamilyFilterChange}
                        style={{ minWidth: '80px' }}
                      >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="YES">YES</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="NULL">NULL</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
                <TableCell style={{ width: '100px' }}>
                  <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <Typography>
                      Pet Friendly
                    </Typography>
                    <FormControl size="small" variant="outlined">
                      <Select
                        value={petFilter}
                        onChange={handlePetFilterChange}
                        style={{ minWidth: '80px' }}
                      >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="YES">YES</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                        <MenuItem value="NULL">NULL</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            {/* Display the filtered data in the table body */}
            <TableBody>
              {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => (
                <TableRow key={data.ID}>
                  <TableCell><Link to={`/breweries/${data.ID}`} style={{ textDecoration: 'none' }}>
                      {data.Name}
                    </Link></TableCell>
                  <TableCell>{data.City}</TableCell>
                  <TableCell>{data.State}</TableCell>
                  <TableCell>{data.tourAvailability}</TableCell>
                  <TableCell>{data.indoorSeating}</TableCell>
                  <TableCell>{data.outdoorSeating}</TableCell>
                  <TableCell>{data.foodAvailability}</TableCell>
                  <TableCell>{data.familyFriendly}</TableCell>
                  <TableCell>{data.petFriendly}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default BreweryTable;